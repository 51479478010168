export const compareStates = function (state1, state2) {
    for(let a=0;a<state1.length;++a) {
        for(let b=0;b<state1[0].length;++b) {
            if(state1[a]?.[b] !== state2[a]?.[b]) {
                return false;
            }
        }
    }
    return true;
}

export const findInState = function(state, val) {

    const ret = [];
    for(let a=0;a<state.length;++a) {
        for(let b=0;b<state[0].length;++b) {
            if(state[a][b] === val) {
                ret.push({x:b,y:a});
            }
        }
    }
    return ret;
}

export const getStateCopy = function (state) {
    return [...state.map(e=>[...e])];
}

export const getPossibleStates = function(state) {
    const empties = findInState(state, -1);
    const ret = [];
    for(const y of [0,-1,1]) {
        for(const x of (y === 0 ? [-1,1] : [0])) {
            for(const empty of empties) {
                if (state[empty.y - y]?.[empty.x - x]) {
                    const copy = getStateCopy(state);
                    const val = state[empty.y - y][empty.x - x];
                    copy[empty.y - y][empty.x - x] = -1;
                    copy[empty.y][empty.x] = val;
                    ret.push(copy);
                }
            }
        }
    }

    return ret;
}

export const containsState = function(arr,state) {
    for(const r of arr) {
        if(compareStates(r,state)) {
            return true;
        }
    }
    return false;
}

export const slidingTiles = function(start, end) {

    if(!end) {
        return [getStateCopy(start)];
    }

    const q = [[start,[]]];
    const visited = [start];
    while(q.length > 0) {
        const [B,path] = q.shift();

        if(compareStates(B,end)) {
            return [...path, B];
        }

        const possibleStates = getPossibleStates(B);

        for(const possibleState of possibleStates) {
            if(containsState(visited, possibleState) === false) {
                q.push([possibleState, [...path,B]]);
                visited.push(possibleState);
            }
        }
    }
}

export const initState = function(w,h,count) {
    if(!count) {
        count = w*h-1;
    }
    const ret = [];

    for(let a=0;a<h;++a) {
        const row = [];
        for(let b=0;b<w;++b) {
            const idx = a * w + b + 1;
            row.push(idx > count ? -1 : idx);
        }
        ret.push(row);
    }
    return ret;
}