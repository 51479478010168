<template>
  <div class="section-container-inner w-full flex mt-4 sm:mt-0 lg:mt-8 text-center socialicons"
       style="padding:1rem">
    <ul class="flex flex-row w-full justify-evenly" v-bind:class="breakpoints">
      <li>
        <a href="https://github.com/dvarnai" class="footer-section-li-link"><img
            src="../icons/Insignificon-Github.svg" class="w-6 inline lg:pr-2" alt="GitHub icon"></a>
      </li>

      <li>
        <a href="https://linkedin.com/in/daniel-varnai" class="footer-section-li-link"><img
            src="../icons/Insignificon-LinkedIn.svg" class="w-6 inline lg:pr-2" alt="LinkedIn icon"></a>
      </li>

      <li>
        <a href="https://instagram.com/daniel.varnai" class="footer-section-li-link"><img
            src="../icons/Insignificon-Instagram-New.svg" class="w-6 inline lg:pr-2" alt="Instagram icon"></a>
      </li>

      <li>
        <a href="https://facebook.com/dvarnai" class="footer-section-li-link"><img
            src="../icons/Insignificon-Facebook-i.svg" class="w-6 inline lg:pr-2" alt="Facebook icon"></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['breakpoints']
}
</script>