import './index.css';

import {createApp} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import Layout from "./layout/Layout.vue";
import Main from "./pages/Main.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/:anchor?', component: Main },
    ]
})

const app = createApp(Layout).use(router).mount('#app');
