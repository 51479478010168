<template>
  <div class="section-container-inner text-lg w-full h-28 sm:h-48 flex">
    <div
        class="flex flex-col flex-shrink justify-center self-center w-full max-w-1/2screen md:w-48 text-center px-2 sm:px-6 work">
      <img :src="logo" class="h-12 sm:h-20 object-contain" :class="{transform: !!scale, ['scale-'+scale]: !!scale}"
           alt="Company logo">
      <div class="text-xs">{{ title }}</div>
      <div class="text-sm">{{ period }}</div>
    </div>
    <div class="flex-grow flex-shrink-0 description self-center text-sm">
      <div class="w-full pr-2 sm:pr-6">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['logo', 'title', 'period', 'scale']
}
</script>
