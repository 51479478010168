<template>
  <div class="section" :class="{'auto-height': this.autoHeight===true}" :data-anchor="this.anchor">
    <SectionLoader ref="loader"></SectionLoader>
    <div v-if="this.backgroundSrc" class="bg-image bg-cover absolute w-full h-full" ref="bg"></div>

    <!-- no container -->
    <slot v-if="this.noContainer===true"></slot>

    <div class="section-container" v-else>
      <div class="section-container-inner md:max-w-3xl">
        <span class="text-white md:text-xl xs:text-lg text-justify leading-8">
          <slot></slot>
        </span>
      </div>
    </div>
  </div>
</template>

<style>

.section {
  transition-duration: inherit;
  transition-timing-function: inherit;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.section .bg-image {
  transition-duration: inherit;
  transition-timing-function: inherit;
  will-change: transform;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.section .section-container {
  transform: translate3d(0, 0, 0);
}

.still .section, .still.section, .still .bg-image {
  transition: none !important;
}
</style>

<script>
import SectionLoader from "./SectionLoader.vue";

export default {
  props: ['anchor', 'backgroundSrc', 'noContainer', 'autoHeight'],
  components: {
    SectionLoader
  },
  data() {
    return {
      currentImg: -1
    }
  },
  methods: {
    isRevealed() {
      return this.$refs.loader.revealed;
    },
    selectBackground() {
      if (!this.backgroundSrc) return;
      if (typeof this.backgroundSrc === 'string') {
        this.$refs['bg'].style.backgroundImage = `url('${this.backgroundSrc}')`;
      } else {
        let selected = 0;
        for (let idx = 0; idx<this.backgroundSrc.images.length; ++idx) {
          const img = this.backgroundSrc.images[idx];

          if (img.width <= window.innerWidth) {
             selected = idx;
          }
        }

        if (selected > this.currentImg) {
          this.$refs['bg'].style.backgroundImage = `url('${this.backgroundSrc.images[selected].path}')`;
          this.backgroundSrc.images[selected].loaded = true;
          this.currentImg = selected;
        }
      }
    }
  },
  mounted() {

    // Select background
    this.selectBackground();

    // Reveal when loaded
    if (this.backgroundSrc) {
      const img = new Image();
      img.onload = img.onerror = () => {
        this.$emit('revealed', this.anchor);
        this.$refs.loader.reveal();
      };
      img.src = this.backgroundSrc;
    } else {
      this.$refs.loader.reveal();
    }
  }
};
</script>
