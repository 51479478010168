<template>
  <div class="section-container flex justify-center items-center mx-4 sm:mx-8 md:mx-4">
    <Slider breakpoint="md">
      <div class="flex-grow md:max-w-100 w-screen md:w-auto xl:w-96 h-160 mx-4 sm:mx-8 md:mx-4 pt-16 sm:pt-0 sm:mt-16">
        <div class="section-container-inner h-full" style="padding: 1.5rem">
        <span class="w-full text-white md:text-xl xs:text-lg text-justify leading-8">
          <h1 class="text-lg sm:text-xl font-normal">Skills</h1>
          <ul class="skills">
            <li>
             Full-Stack Development
              <span class="text-xs float-right mt-2">
                Expert
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 95%, #9ca3af 95%, #9ca3af 100%)"></span>
            </li>
            <li>
              Digital Advertising
              <span class="text-xs float-right mt-2">
                Expert
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 90%, #9ca3af 90%, #9ca3af 100%)"></span>
            </li>
                        <li>
            Entrepeneurship
              <span class="text-xs float-right mt-2">
                Expert
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 80%, #9ca3af 80%, #9ca3af 100%)"></span>
            </li>
            <li>
            DevOps
              <span class="text-xs float-right mt-2">
                Expert
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 80%, #9ca3af 80%, #9ca3af 100%)"></span>

            </li>
            <li>
            Containerization
              <span class="text-xs float-right mt-2">
                 Expert
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 75%, #9ca3af 75%, #9ca3af 100%)"></span>
            </li>
            <li>
             Cloud
              <span class="text-xs float-right mt-2">
                Expert
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 75%, #9ca3af 75%, #9ca3af 100%)"></span>
            </li>
            <li>
             Statistics
              <span class="text-xs float-right mt-2">
                Proficient
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 70%, #9ca3af 70%, #9ca3af 100%)"></span>
            </li>
            <li>
             Deep Learning
              <span class="text-xs float-right mt-2">
                Proficient
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 65%, #9ca3af 65%, #9ca3af 100%)"></span>
            </li>
            <li>
             Artificial Intelligence
              <span class="text-xs float-right mt-2">
               Proficient
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 65%, #9ca3af 65%, #9ca3af 100%)"></span>
            </li>
            <li>
             Research
              <span class="text-xs float-right mt-2">
                Proficient
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 51%, #9ca3af 51%, #9ca3af 100%)"></span>
            </li>
          </ul>
        </span>
        </div>
      </div>
      <div class="w-screen md:w-1/2 md:max-w-100 xl:max-w-none xl:w-auto flex flex-col mx-4 sm:mx-8 md:mx-4">
        <h1 class="text-2xl text-white text-center mb-2 sm:mb-8">Experience</h1>
        <div
            class="grid grid-cols-2 gap-4 xl:grid-cols-3 md:gap-8 w-full xl:w-160 sm:h-auto experience px-4 sm:px-0 self-center"
            style="align-items: stretch" ref="experience">
          <Work logo="../img/motdgd.png?size=128" title="Lead Developer" period="2017 - Present">
            Digital advertising startup focusing on PC games.
          </Work>
          <Work logo="../img/fieryai.png?size=128" title="Director" period="2020 - Present" :scale="75">
            AI startup offering consulting for digital transformation.
          </Work>
          <Work logo="../img/qvai.png?size=128" title="Director" period="2021 - Present">
            AI startup focusing on real-time tracking of sport matches.
          </Work>
          <Work logo="../img/mediacogs.png?size=128" title="Director" period="2018 - 2021">
            Online ad fraud detection and prevention solution.
          </Work>
          <Work logo="../img/imperial.png?size=128" title="Research Assistant" period="2018 - 20219">
            Research on real-time gaze estimation on mobile devices.
          </Work>
          <Work logo="../img/uob.png?size=256" title="Research Assistant" period="2019" :scale="150">
            Research on inverse kinematics and human body pose estimation.
          </Work>
          <Work logo="../img/wigner.png?size=128" title="Research Intern" period="2013" :scale="125">
            Research on surface scanning of GEM detectors.
          </Work>
          <Work logo="../icons/Computercoding.svg" title="Freelancer" period="2012-2017" :scale="75">
            Online freelancing serving 100+ customers.
          </Work>
        </div>
      </div>
    </Slider>
  </div>
</template>

<script>
import Work from "../components/Work.vue";
import {findInState, getStateCopy, initState, slidingTiles} from "../sliding-tiles";
import Slider from "../components/Slider.vue";

const Pipeline = function (elm) {
  this.elm = elm;
  this.animation = null;
  this.pipeline = [];
  this.playing = false;
  this.timers = [];
  this.onPositionChanged = () => {
  };
}

Pipeline.prototype.add = function (rules, len, pos) {

  this.pipeline.push({rules, len, pos});
}

Pipeline.prototype.clear = function () {
  this.pipeline = [];
}

Pipeline.prototype.onAnimationStart = function (e) {

  this.playing = true;

  let delay = 0;
  for (const item of this.pipeline) {
    setTimeout(() => {
      this.onAnimationKeyframe(item)
    }, delay);
    delay += item.len;
  }
}

Pipeline.prototype.onAnimationIteration = function () {

}

Pipeline.prototype.onAnimationKeyframe = function (e) {

  if (e.pos) {
    setTimeout(() => {
      if (this.pl)
        this.onPositionChanged(e);
    }, e.len);
  }
}

Pipeline.prototype.onAnimationEnd = function (e) {

  this.playing = false;
}

Pipeline.prototype.play = function (delay) {

  if (this.playing) return;

  let len = this.pipeline.reduce((acc, e) => {
    return acc + e.len
  }, 0);
  let offset = 0;
  const anim = this.elm.animate(this.pipeline.map(e => {
    offset += e.len;
    return {
      ...e.rules,
      offset: offset / len,
    }
  }), {duration: len, fill: 'forwards', delay});

  anim.addEventListener('finish', this.onAnimationEnd.bind(this));
  this.animation = anim;
  this.onAnimationStart(anim);
}

Pipeline.prototype.pause = function () {
  this.animation?.pause();
  this.playing = false;
}

Pipeline.prototype.stop = function () {
  this.pause();
  this.clear();
  for (const timer of this.timers) {
    clearTimeout(timer);
  }
  this.timers = [];
}

const Item = function (elm, pos) {
  this.elm = elm;
  this.origPos = pos;
  this.pos = pos;
  this.onPositionChanged = () => {
  };
  this.pipeline = new Pipeline(this.elm);
  this.pipeline.onPositionChanged = (e) => {

    this.pos = e.pos;
    this.onPositionChanged(e, this.pos, e.pos);
  };
}

Pipeline.prototype.clearTimers = function () {
  for (const timer of this.timers) {
    clearTimeout(timer);
  }
  this.timers = [];
}

export default {
  components: {Slider, Work},
  data() {
    return {
      animSpeed: 150,
      grid: null,
      delay: null,
      opening: null,
      resetTimer: null,
      openTimer: null
    }
  },

  methods: {

    gridSize() {
      return [
        window.getComputedStyle(this.$refs.experience).gridTemplateColumns.split(' ').length,
        window.getComputedStyle(this.$refs.experience).gridTemplateRows.split(' ').length
      ];
    },

    gridDims() {
      return [
        window.getComputedStyle(this.$refs.experience).gridTemplateColumns.split(' ')[0],
        window.getComputedStyle(this.$refs.experience).gridTemplateRows.split(' ')[0]
      ];
    },

    gapSize() {
      return window.getComputedStyle(this.$refs.experience).columnGap;
    },

    findPath(start, end, state) {

      const path = [];
      while (start.x !== end.x || start.y !== end.y) {
        if (start.y !== end.y) {
          path.push(state[start.y][start.x]);
          start.y += end.y - start.y < 0 ? -1 : 1;
        } else if (start.x !== end.x) {
          path.push(state[start.y][start.x]);
          start.x += end.x - start.x < 0 ? -1 : 1;
        }
      }
      path.push(state[end.y][end.x]);
      return path;
    },

    pathToStates(state, path) {
      let empty = findInState(state, -1)[0];
      let prevState = state;
      const ret = [];
      for (let i = path.length - 2; i >= 0; --i) {
        const c = findInState(prevState, path[i])[0];
        const newState = getStateCopy(prevState);
        newState[empty.y][empty.x] = path[i];
        newState[c.y][c.x] = -1;
        empty = c;
        prevState = newState;
        ret.push(newState);
      }
      return ret;
    },

    open(pos) {

      const elm = this.$refs.experience.children[pos.y * this.grid[0].length + pos.x];
      if (this.opening === elm) {
        return;
      }

      const endTile = pos.x === this.grid[0].length - 1;
      const clear = {x: endTile ? pos.x - 1 : pos.x + 1, y: pos.y};
      const start = initState(this.grid[0].length, this.grid.length, this.$refs.experience.children.length);
      const emptyPos = findInState(start, -1)[0];
      const path = this.findPath(clear, emptyPos, start);
      const states = path[0] === -1 ? [start] : this.pathToStates(start, path);

      if (endTile) {
        const copy = getStateCopy(states[states.length - 1]);
        const e = findInState(copy, -1)[0];
        copy[e.y][e.x] = start[pos.y][pos.x];
        copy[pos.y][pos.x] = -1;
        states.push(copy);
      }

      const cleanStates = slidingTiles(this.grid, states[states.length - 1]);

      this.stopAll();
      this.resetTiles(elm);
      let delay = this.executeStates(cleanStates);

      const anim = elm.animate([
        {width: `calc(200% + ${this.gapSize()})`}
      ], {duration: this.animSpeed, delay: delay, fill: 'both'});

      this.opening = elm;
      anim.onfinish = () => {
        this.opening = null;

        if (anim.replaceState !== 'removed') {
          elm.classList.add('open');
        }
      };

      start[clear.y][clear.x] = -1;
    },

    resetTiles(exception) {
      for (const child of this.$refs.experience.children) {
        if (child === exception) continue;

        if (child.classList.contains('open')) {
          child.classList.remove('open');
        }
        const anim = child.animate([
          {width: `100%`}
        ], {duration: 300, delay: 0, fill: 'both'});
      }
    },

    executeStates(states) {
      let delay = 0;
      for (const state of states) {
        this.executeState(state, delay);
        delay += this.animSpeed;
      }
      return delay;
    },

    executeState(state, delay) {
      for (let y = 0; y < state.length; ++y) {
        for (let x = 0; x < state[0].length; ++x) {
          if (state[y][x] === -1) continue;
          const orig = {x: (state[y][x] - 1) % state[0].length, y: Math.floor((state[y][x] - 1) / state[0].length)};
          const dir = [x - orig.x, y - orig.y];
          const anim = this.$refs.experience.children[state[y][x] - 1].animate([
            {transform: `translate(calc(${dir[0]} * ${this.gridDims()[0]}  + ${dir[0]} * ${this.gapSize()}), calc(${dir[1]} * ${this.gridDims()[1]}  + ${dir[1]} * ${this.gapSize()}))`}
          ], {duration: this.animSpeed, delay: delay, fill: 'both'});
          anim.onfinish = () => {
            if (anim.replaceState !== 'removed') {
              this.grid = state;
            }
          };
        }
      }
    },

    stopAll() {
      for (const child of this.$refs.experience.children) {
        child.getAnimations().forEach((e) => {
          if (e.effect.getKeyframes()[0].width && e.pending === false) {
            return;
          }

          e.pause();
        })
      }
    },

    reset() {

      const states = slidingTiles(this.grid, initState(this.grid[0].length, this.grid.length, this.$refs.experience.children.length));
      this.stopAll();
      this.resetTiles();
      this.opening = null;

      this.executeStates(states);

    }
  },

  mounted() {
    const items = this.$refs.experience.children;
    this.grid = initState(this.gridSize()[0], this.gridSize()[1] + (items.length % this.gridSize()[0] === 0 ? 1 : 0), items.length);
    window.addEventListener('resize', () => {
      this.grid = initState(this.gridSize()[0], this.gridSize()[1] + (items.length % this.gridSize()[0] === 0 ? 1 : 0), items.length);
      this.executeState(this.grid, 0);
    });

    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      item.onmouseover = () => {
        if (this.resetTimer) {
          clearTimeout(this.resetTimer);
          this.resetTimer = null;
        }

        let timeout = 0;
        if (this.opening) {
          timeout = 500;
        }
        if (this.openTimer) {
          clearTimeout(this.openTimer);
        }
        this.openTimer = setTimeout(() => {
          this.open({x: i % this.grid[0].length, y: Math.floor(i / this.grid[0].length)});
        }, timeout);
      }

      item.onclick = () => {
        this.open({x: i % this.grid[0].length, y: Math.floor(i / this.grid[0].length)});
      }

      item.onmouseout = () => {

        if (this.openTimer) {
          clearTimeout(this.openTimer);
          this.openTimer = null;
        }

        this.resetTimer = setTimeout(() => {
          this.reset();
          this.resetTimer = null;
        }, 3000);
      }
    }
  }
}
</script>
