<template>
  <div id="layout" class="flex flex-col">
    <Header></Header>
    <router-view class="bg-black"></router-view>
  </div>
</template>

<style>
.redraw {
  height: 100vh;
}
</style>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Main from "../pages/Main.vue";
import PageLoader from "../components/PageLoader.vue";

export default {
  components: {
    PageLoader,
    Header, Footer, Main
  }
}
</script>
