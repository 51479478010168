<template>
  <main>
    <FullPage id="fullpage" :options="options">
      <Section anchor="about" :background-src="backgrounds[0]" :no-container="true">
        <About></About>
      </Section>

      <Section anchor="experience" :background-src="backgrounds[1]" :no-container="true">
        <Experience></Experience>
      </Section>

      <Section anchor="contact" :background-src="backgrounds[2]" :no-container="true">
        <Contact></Contact>
      </Section>

      <Section anchor="footer" :no-container="true" :auto-height="true">
        <Footer></Footer>
      </Section>
    </FullPage>
  </main>
</template>

<script>
import Footer from "../layout/Footer.vue";
import Section from "../components/Section.vue";
import FullPage from "../components/FullPage.vue";
import ContactForm from "../components/ContactForm.vue";

import bg1 from '../img/bg6.jpg?sizes[]=800,sizes[]=1280,sizes[]=1920';
import bg2 from '../img/bg13.jpg?sizes[]=800,sizes[]=1280,sizes[]=1920';
import bg3 from '../img/bg9.jpg?sizes[]=800,sizes[]=1280,sizes[]=1920';
import About from "../sections/About.vue";
import Experience from "../sections/Experience.vue";
import Contact from "../sections/Contact.vue";

export default {
  components: {
    Contact,
    Experience,
    About,
    Section,
    Footer,
    FullPage,
    ContactForm
  },
  data() {
    return {
      backgrounds: [
        bg1,
        bg2,
        bg3
      ],
      options: {
        scrollable: false
      }
    }
  },
  beforeMount() {
    this.options.scrollBar = false;
  },
  beforeDestroy() {
    this.options.scrollBar = true;
  },
  async mounted() {
  },
  methods: {}
}
</script>
