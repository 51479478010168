<template>
  <div class="w-screen sm:w-full md:w-152 lg:w-120 flex flex-col mx-4 sm:mx-0 md:mr-8">
    <h1 class="text-2xl text-white text-center mb-2 sm:mb-8">Get in touch with me</h1>
    <div class="section-container-inner flex flex-col flex-grow justify-center items-center" v-if="sending===2">
      <img src="../icons/Sent.svg" class="w-24 mx-auto mb-4" alt="E-mail sent icon">
      <div class="text-lg text-white text-center">
        Your message has been successfully sent.<br>I'll get back to you shortly!
      </div>
    </div>
    <div class="section-container-inner flex-col justify-center" v-else>

      <div class="text-sm sm:text-base text-white mb-8">
        <img src="../icons/Encrypted.svg" class="w-16 float-left mr-4" alt="Encryption icon">
        <span style="font-weight:400">Complete privacy.</span>
        Your message will be encrypted before
        it ever leaves your device ensuring that only I can read it.
      </div>

      <form class="w-full text-white text-base sm:text-lg px-2">

        <div class="input-group">
          <label for="name">Your name</label>
          <input type="text" name="name" id="name" ref="name" class="w-full mb-4 sm:mb-8" v-model="form.name">
        </div>

        <div class="input-group">
          <label for="email">Your e-mail</label>
          <input type="email" name="email" id="email" ref="email" class="w-full mb-4 sm:mb-8" v-model="form.email"
                 @blur="lookForPublicKey()">
        </div>

        <div class="input-group">
          <label for="subject">Subject</label>
          <input type="text" name="subject" ref="subject" id="subject" class="w-full mb-4 sm:mb-8"
                 v-model="form.subject">
        </div>

        <div class="input-group">
          <label for="body">Your message</label>
          <textarea name="body" id="body" ref="body" class="w-full mb-4 sm:mb-8" v-model="form.body"></textarea>
        </div>

        <div class="flex items-center">
          <div
              class="items-center leading-8 border-dashed border-2 rounded-sm md:pl-2 pr-2 sm:pr-12 py-2 cursor-pointer text-sm lg:text-lg"
              v-bind:class="{'border-blue-400': fileDragged, 'border-transparent': !fileDragged}"
              @dragenter="onDragEnter" @dragover="onDragOver" @dragleave="onDragEnd" @drop="onFileDrop"
              @click="$refs.keyfile.click()">
            <input type="file" class="hidden" ref="keyfile" @change="onKeyChanged">

            <div v-if="publicKey===null">
              <img src="../icons/Key.svg" class="w-8 float-left mr-4" alt="Key icon"> Attach public key
            </div>
            <div v-else>
              <img src="../icons/Lock.svg" class="w-8 float-left mr-4" alt="Lock icon"> Public key
              {{ attachedPublicKey ? 'attached' : 'found' }}
            </div>

            <div v-if="keyError" class="text-red-500 float-left text-sm">
              Invalid key file
            </div>
          </div>
          <div class="flex-grow text-right">
            <vue-hcaptcha sitekey="d18cbc08-2c64-4132-9059-a0f4238bd3ce" size="invisible" @verify="onVerify"
                          ref="captcha"></vue-hcaptcha>
            <button class="btn" @click="$refs.captcha.execute();$event.preventDefault()" :disabled="!canSend">Send
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="hidden sm:block lg:hidden mt-8">
      <AboutSocial :row="true"></AboutSocial>
    </div>
  </div>
</template>

<script>
import {validate} from 'email-validator';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import AboutSocial from "../components/AboutSocial.vue";

const publicKeyUrl = require('../static/pubkey.asc');

export default {
  components: {
    VueHcaptcha,
    AboutSocial
  },
  data() {
    return {
      publicKey: null,
      attachedPublicKey: false,
      fileDragged: false,
      keyError: false,
      sending: 0,
      form: {}
    }
  },
  computed: {
    canSend() {
      return this.form.name?.trim().length > 0
          && validate(this.form.email)
          && this.form.subject?.trim().length > 0
          && this.form.body?.trim().length > 0
    },
  },
  methods: {

    async sendMessage(token) {

      const publicKeyText = await (await fetch(publicKeyUrl)).text();
      const publicKey = await this.openpgp.readKey({armoredKey: publicKeyText});
      const encrypted = await this.openpgp.encrypt({
        message: await this.openpgp.createMessage({
          text: this.$refs.body.value
        }),
        encryptionKeys: publicKey
      });

      const request = {
        content: {
          email: `${this.$refs.name.value} <${this.$refs.email.value}>`,
          subject: this.$refs.subject.value,
          message: encrypted,
          pubkey: this.publicKey
        },
        captcha: token
      };

      this.sending = 1;
      const response = await (await fetch('/api/sendMessage', {
        method: 'POST', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, body: JSON.stringify(request)
      })).json();

      if (response.success) {
        this.sending = 2;
      }
    },

    async onVerify(token, ekey) {
      this.sendMessage(token);
    },

    async lookForPublicKey() {
      if (validate(this.$refs.email.value)) {
        this.publicKey = await this.getPublicKey(this.$refs.email.value);
      }
    },

    async getPublicKey(address) {
      return this.getProtonPublicKey(address)
          .catch(e => this.getOpenPGPPublicKey(address))
          .catch(e => this.getServerPublicKey(address));
    },

    async getProtonPublicKey(address) {
      try {
        return (await fetch(`https://api.protonmail.ch/pks/lookup?op=get&search=${address}`)).text();
      } catch (e) {
        return null;
      }
    },

    async getOpenPGPPublicKey(address) {
      try {
        return (await fetch(`https://keys.openpgp.org/vks/v1/by-email/${address}`)).text();
      } catch (e) {
        return null;
      }
    },

    async getServerPublicKey(address) {
      try {
        return (await fetch(`/api/lookupAddress?search=${address}`)).text();
      } catch (e) {
        return null;
      }
    },

    onDragEnter(ev) {
      ev.preventDefault();
      this.fileDragged = true;
    },

    onDragOver(ev) {
      ev.preventDefault();
      this.fileDragged = true;
    },

    onDragEnd(ev) {
      ev.preventDefault();
      this.fileDragged = false;
    },

    async onKeyChanged(ev) {
      return this.processFiles(this.$refs.keyfile.files);
    },

    async onFileDrop(ev) {
      ev.preventDefault();
      this.fileDragged = false;

      if (!ev.dataTransfer.files) {
        return;
      }

      return this.processFiles(ev.dataTransfer.files);
    },

    async initOpenPGP() {
      if(this.openpgp) return;
      this.openpgp = await import('openpgp/lightweight');
    },

    async processFiles(files) {

      await this.initOpenPGP();

      // reset keys
      this.publicKey = null;
      this.attachedPublicKey = false;

      for (const file of files) {
        try {
          const publicKeyText = await file.text();
          await this.openpgp.readKey({armoredKey: publicKeyText});
          this.publicKey = publicKeyText;
          this.attachedPublicKey = true;
          this.keyError = false;
        } catch (e) {
          this.keyError = true;
        }
      }
    }
  }
}
</script>
