<template>
  <div class="fullpage still" ref="fullpage">
    <slot></slot>
  </div>
</template>

<style>
.fullpage {
  height: 100%;
  transition-duration: 0s;
  transition-timing-function: ease-in-out;
  transition-property: transform;
  will-change: transform;
  scroll-behavior: smooth;
}

.still .fullpage {
  transition: none !important;
}

.redraw .fullpage * {
  display: none;
}
</style>

<script>
export default {
  props: ['options'],
  methods: {
    redraw() {

      if (this.screenHeight === window.innerHeight) {
        return;
      }
      this.screenHeight = window.innerHeight;

      const e = this.$refs['fullpage'];
      document.querySelector('#layout').classList.add('redraw');
      this.containerHeight = window.innerHeight; //e.getBoundingClientRect().height;

      const sections = e.querySelectorAll('.section');
      for (const section of sections) {

        // auto height, but limit if bigger than container height
        if (section.classList.contains('auto-height')) {
          section.style.maxHeight = `${this.containerHeight}px`;
          continue;
        }
        section.style.height = `${this.containerHeight}px`;
      }
      document.querySelector('#layout').classList.remove('redraw');

      this.calcParallax();
      this.slideTo(this.currentSlide);
    },

    slideTo(idx = 0, animation, animTime) {

      const e = this.$refs['fullpage'];
      const sections = e.querySelectorAll('.section');
      if (!sections[idx] || idx >= sections.length) return;
      const section = sections[idx].getBoundingClientRect();

      if (!animation && !animTime) {
        const anchor = sections[idx].getAttribute('data-anchor');
        if (anchor !== this.$route.params.anchor) {
          this.goto(anchor);
        }
      }

      const totalTransTime = this.opts.scrollTimeout;
      const transTime = animTime || Math.floor(totalTransTime / Math.abs(idx - this.currentSlide));

      if (idx - this.currentSlide > 1) {
        this.slideTo(idx - 1, animation ? (this.currentSlide + 1 === idx - 1 ? 'easy-in' : 'linear') : 'easy-out', transTime);
      } else if (idx - this.currentSlide < -1) {
        this.slideTo(idx + 1, animation ? (this.currentSlide - 1 === idx + 1 ? 'easy-in' : 'linear') : 'easy-out', transTime);
      }

      const first = sections[0].getBoundingClientRect();
      for(const f of e.querySelectorAll('.section')) {
        f.style.transitionDuration = Math.round(transTime) + 'ms';
        f.style.transitionTimingFunction = animation;
        f.style.transform = `translate3d(0,-${section.top - first.top - (this.containerHeight - section.height)}px,0)`;
      }

      let prevBg, nextBg;
      if (this.currentSlide - 1 >= 0) {
        prevBg = sections[this.currentSlide - 1].querySelector('.bg-image');
      }
      const curBg = sections[this.currentSlide].querySelector('.bg-image');
      if (this.currentSlide + 1 < sections.length) {
        nextBg = sections[this.currentSlide + 1].querySelector('.bg-image');
      }
      const offset = Math.floor(section.height * (this.opts.parallax + 0.05 * idx));
      if (this.currentSlide > idx) {
        this.translateY(prevBg, 0);
        this.translateY(curBg, -offset);
      } else if (this.currentSlide < idx) {
        this.translateY(nextBg, 0);
        this.translateY(curBg, offset);
      } else {
        this.translateY(prevBg, offset);
        this.translateY(curBg, 0);
        this.translateY(nextBg, -offset);
      }

      this.currentSlide = idx;
    },

    translateY(e, y) {
      if (e) {
        e.style.transform = `translate3d(0,${y}px,0)`;
      }
    },

    setStill(set, time) {
      if (time) {
        setTimeout(() => this.setStillHelper(set), time);
      } else {
        this.setStillHelper(set);
      }
    },

    setStillHelper(set) {
      if (set) {
        this.$refs['fullpage'].classList.add('still');
      } else {
        this.$refs['fullpage'].classList.remove('still');
      }
    },

    calcParallax() {
      this.setStill(true);
      const scrollable = this.isScrollable();
      const docHeight = document.body.getBoundingClientRect().height;
      const p = window.scrollY / document.body.scrollHeight;
      const pBottom = (window.scrollY + window.screen.height) / document.body.scrollHeight;
      const sections = Array.from(this.$refs['fullpage'].querySelectorAll('.section'));
      const headOffset = sections[0].getBoundingClientRect().top + window.scrollY;

      // Calculate parallax of all sections
      for (const idx in sections) {
        const i = parseInt(idx);
        const section = sections[idx];
        if (!section.querySelector('.bg-image')) continue;

        const rect = section.getBoundingClientRect();
        const absTop = rect.top + window.scrollY;
        const absBottom = rect.bottom + window.scrollY;

        // Skip if not in view
        if (pBottom < absTop / docHeight || p > absBottom / docHeight) {
          continue;
        }

        // Calculate parallex limits
        const prevHeight = sections[idx - 1]?.getBoundingClientRect().height || 0;
        const offset = Math.floor(rect.height * this.opts.parallax);
        const pminStart = Math.max((absTop - prevHeight - headOffset) / docHeight, 0);
        const pminEnd = Math.min((absTop - headOffset) / docHeight, 1);
        const pmaxStart = Math.max((absBottom - rect.height - headOffset) / docHeight, 0);
        const pmaxEnd = Math.min((absBottom - headOffset) / docHeight, 1);

        // Push anchor url
        if (this.isScrollable()) {
          if (pminEnd <= p && p <= pmaxEnd) {
            const anchor = sections[idx].getAttribute('data-anchor');
            if (anchor !== this.$route.params.anchor) {
              this.goto(anchor);
            }
          }
        }

        // Calculate ratio
        let sp;
        if (pminStart <= p && p <= pminEnd) {
          sp = -(1 - (p - pminStart) / (pminEnd - pminStart)) || -1;
        } else if (pmaxStart <= p && p <= pmaxEnd) {
          sp = (p - pmaxStart) / (pmaxEnd - pmaxStart);
        } else {
          sp = scrollable ? (p < pminStart ? -1 : (pmaxEnd < p ? 1 : 0)) : (this.currentSlide < i ? -1 : (this.currentSlide === i ? 0 : 1));
        }

        if (i === 0 && sp < 0) {
          sp = 0;
        }

        section.querySelector('.bg-image').style.transform = `translate3d(0,${offset * sp}px,0)`;
      }
      this.setStill(false, 1);
    },

    isScrollable() {
      return document.body.style.overflow !== 'hidden';
    },

    slideToAnchor(a, animations = true) {
      const anchor = a || this.$route.params.anchor;
      if (anchor) {

        // Get section
        const sections = Array.from(this.$refs['fullpage'].querySelectorAll('.section'));
        const section = this.$refs['fullpage'].querySelector(`.section[data-anchor="${anchor}"]`);

        // Slide to anchor if it exists and it's not current slide
        if (section) {
          const idx = sections.indexOf(section);
          if (idx !== this.currentSlide) {
            this.slideTo(idx, animations ? undefined : '', animations ? undefined : 1);
          }
        }
      }
    },

    goto(anchor) {
      this.skipWatch = true
      this.$router.push(`/${anchor}`);
      setTimeout(() => {
        this.skipWatch = false;
      }, 1);
    }
  },
  data() {
    return {
      redrawTimer: null,
      skipWatch: false,
      containerHeight: 0,
      currentSlide: 0,
      scrollTimeout: 0,
      screenHeight: 0,
      opts: {
        scrollable: false,
        parallax: 0.3,
        scrollTimeout: 1500
      }
    }
  },
  watch: {
    $route(to, from) {
      if (from.params.anchor !== this.$route.params.anchor && !this.skipWatch) {
        this.slideToAnchor();
      }
    }
  },
  mounted() {

    this.slideToAnchor(null, false);
    this.opts = {...this.opts, ...this.options};

    window.addEventListener('resize', () => {
      this.redraw();
    }, false);

    document.onscroll = (ev) => {
      if (this.opts.scrollable && !this.redrawTimer) {
        ev.stopPropagation();

        this.calcParallax();
      }
    };

    // Mouse wheel move
    document.body.onwheel = (ev) => {
      // Not scrollable
      if (!this.opts.scrollable) {

        if (this.scrollTimeout < new Date()) {
          ev.stopPropagation();

          // Go to next/prev slide
          this.slideTo(ev.deltaY < 0 ? this.currentSlide - 1 : this.currentSlide + 1);
          this.scrollTimeout = new Date(new Date().getTime() + this.opts.scrollTimeout);
        }
      }
    };

    let touchStart;
    this.$refs['fullpage'].ontouchstart = (ev) => {
      // Log start to calculate delta
      touchStart = ev.touches[0].clientY;
    };
    this.$refs['fullpage'].ontouchmove = (ev) => {

      // Prevent drag
      ev.preventDefault();

      // Scrollable
      if (this.opts.scrollable) {
        if (this.redrawTimeout) {
          clearTimeout(this.redrawTimer);
        }
        this.redrawTimeout = setTimeout(() => {
          this.redrawTimeout = null;
          this.calcParallax()
        }, 1);
        // Non-scrollable
      } else {
        if (this.scrollTimeout < new Date() && Math.abs(ev.touches[0].clientY - touchStart) > window.innerHeight*0.1) {

          // Slide to next/prev slide
          this.slideTo(ev.touches[0].clientY - touchStart > 0 ? this.currentSlide - 1 : this.currentSlide + 1);
          this.scrollTimeout = new Date(new Date().getTime() + this.opts.scrollTimeout);
        }
      }
    };

    if (this.opts.scrollable) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }

    this.redraw();
  },

  destroyed() {
    document.body.style.overflow = 'visible';
  }
};
</script>
