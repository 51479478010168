<template>
  <div class="section-container flex justify-center">
    <div class="w-full flex flex-col sm:px-4 md:mx-0">

      <h1 class="text-4xl md:text-8xl text-white text-center mb-2 md:mb-8 text-shadow-lg main-header" ref="title">Hi.
        I'm Daniel.</h1>

      <Slider>

        <div class="flex flex-col mx-4 sm:ml-0 sm:mr-8 flex sm:hidden md:flex" ref="firstCol">
          <div
              class="section-container-inner w-screen-margin sm:w-56 flex-col flex-grow flex-shrink-0 items-center justify-center text-border-1 flex">
            <img src="../icons/Developer.svg" class="w-1/2 sm:w-full md:p-12 lg:p-0" draggable="false" alt="A line icon of a person.">
          </div>

          <div class="sm:hidden">
            <AboutSocial></AboutSocial>
          </div>
        </div>

        <div
            class="w-screen-margin sm:w-96 flex-col sm:flex-row md:flex-col flex-grow lg:flex-grow-0 flex-shrink-0 justify-top flex sm:mx-0 mx-4"
            ref="secondCol">
          <div class="section-container-inner sm:flex-grow sm:mr-4 md:mr-0 lg:mr-8">
            <span class="w-full text-white md:text-xl xs:text-lg text-justify leading-8">
              <h1 class="text-xl font-normal">About me</h1>
              <ul class="skills">
                <li>
                  From
                  <span class="text-sm float-right mt-2">
                   Budapest, Hungary
                  </span>
                </li>

                <li>
                 Currently in
                  <span class="text-sm float-right mt-2">
                   Budapest, Hungary
                  </span>
                </li>
                <li>
                  Age
                  <span class="text-sm float-right mt-2">
                    27
                  </span>
                </li>
              </ul>
            </span>
          </div>

          <div class="section-container-inner flex-grow mt-4 sm:mt-0 md:ml-0 lg:mr-8 md:mt-8">
            <span class="w-full text-white md:text-lg xs:text-sm leading-8">
              <h1 class="text-xl font-normal mb-2">Work</h1>
              <ul class="flex flex-col h-full">
                <li>Entrepreneur.</li>
                <li class="mt-2">Software Engineer.</li>
                <li class="mt-2">World Traveller.</li>
              </ul>
            </span>
          </div>
        </div>

        <div
            class="w-screen-margin mx-4 sm:mx-0 sm:w-full lg:w-96 flex flex-col sm:flex-row lg:flex-col justify-top pt-4 md:pt-8 lg:pt-0"
            ref="thirdCol">
          <div class="section-container-inner flex-grow">
        <span class="w-full text-white md:text-xl xs:text-lg text-justify leading-8">
          <h1 class="text-xl font-normal">Languages</h1>
          <ul class="skills">
            <li>
              Hungarian
              <span class="text-xs float-right mt-2">
                Native
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 100%, #9ca3af 100%, #9ca3af 100%)"></span>
            </li>
            <li>
              English
              <span class="text-xs float-right mt-2">
               Fluent
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 90%, #9ca3af 90%, #9ca3af 100%)"></span>
            </li>
            <li>
              French
              <span class="text-xs float-right mt-2">
               Conversational
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 60%, #9ca3af 60%, #9ca3af 100%)"></span>
            </li>
            <li>
              Spanish
              <span class="text-xs float-right mt-2">
                Conversational
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 60%, #9ca3af 60%, #9ca3af 100%)"></span>
            </li>
            <li>
              Mandarin
              <span class="text-xs float-right mt-2">
               Beginner
              </span>
              <span class="progress"
                    style="background: linear-gradient(90deg, #1e90ff 0%, #1e90ff 10%, #9ca3af 10%, #9ca3af 100%)"></span>
            </li>
          </ul>
        </span>
          </div>

          <div class="sm:ml-4 md:ml-8 lg:ml-0 flex">
            <AboutSocial :breakpoints="{'sm:flex-col': true, 'lg:flex-row': true}"></AboutSocial>
          </div>
        </div>
      </Slider>
    </div>
  </div>
</template>

<script>
import AboutSocial from "../components/AboutSocial.vue";
import Slider from "../components/Slider.vue";

export default {
  components: {Slider, AboutSocial},
  methods: {},

  mounted() {
    setTimeout(() => {
      this.$refs.title.style.opacity = '100%';
    }, 1);
  }
}
</script>
