<template>
  <header id="header">
    <div id="navbar">
      <div id="navbar-logo-container">
        <router-link to="/">
          <img src="../img/logo.png" class="w-12" alt="Logo">
        </router-link>
        <p id="hamburgerbtn">
          <img src="../icons/Menu.svg" class="w-6" alt="Menu icon">
        </p>
      </div>
      <div id="navbar-menu-container">
        <ul id="navbar-menu-container-menu">
          <li>
            <router-link class="navbar-menu-link" to="/about">
              About
            </router-link>
          </li>
          <li>
            <router-link class="navbar-menu-link" to="/experience">
              Clients
            </router-link>
          </li>
          <li>
            <router-link class="navbar-menu-link" to="/contact">
              Contact
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>

export default {

  mounted() {
    const hamburger = document.getElementById('hamburgerbtn');
    const mobileMenu = document.getElementById('header');
    hamburger.addEventListener('click', function () {
      mobileMenu.classList.toggle('hamburgActive');
    });
  }
}
</script>
