<template>
  <div class="absolute w-full flex bg-black" style="z-index:100;height: 100% !important;" ref="loader">
    <span class="mx-auto my-auto">
      <img src="../img/logo.png" class="w-8" alt="Logo">
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      revealed: false
    }
  },
  methods: {
    reveal() {
      this.$refs.loader.className += " animate-fade-out";
      setTimeout(() => {
        this.$refs.loader.parentNode.removeChild(this.$refs.loader);
        this.revealed = true;
        this.$emit('revelead');
      }, 1500);
    }
  }
}
</script>
