<template>
  <div class="section-container flex justify-center contact sm:flex-col md:flex-row">
    <Slider>
      <ContactForm></ContactForm>
      <div class="flex flex-col w-screen sm:w-96 mx-4 sm:mx-0 sm:hidden lg:flex">
        <div class="section-container-inner flex-col text-white text-lg social" style="margin-top:4rem">
          <span class="font-normal text-xl w-96">Find me on social media</span>
          <br/>
          <ul class="mt-2 font-light">
            <li class="mb-4">
              <a href="https://github.com/dvarnai"><img
                  src="../icons/Insignificon-Github.svg" class="w-10 inline pr-4" alt="GitHub icon">GitHub</a>
            </li>

            <li class="mb-4">
              <a href="https://linkedin.com/in/daniel-varnai"><img
                  src="../icons/Insignificon-LinkedIn.svg" class="w-10 inline pr-4" alt="LinkedIn icon">LinkedIn</a>
            </li>

            <li class="mb-4">
              <a href="https://instagram.com/daniel.varnai"><img
                  src="../icons/Insignificon-Instagram-New.svg" class="w-10 inline pr-4" alt="Instagram icon">Instagram</a>
            </li>

            <li>
              <a href="https://facebook.com/dvarnai"><img
                  src="../icons/Insignificon-Facebook-i.svg" class="w-10 inline pr-4" alt="Facebook icon">Facebook</a>
            </li>
          </ul>
        </div>

        <div class="section-container-inner text-white text-xl" style="margin-top:2rem">
          <img src="../icons/OutOfBox.svg" class="w-8 inline mr-4" alt="A line icon of an open box.">
          Let's think together...
        </div>

        <div class="transform-gpu text-lg text-white text-7xl font-light text-shadow-lg text-center"
             style="margin-top:4rem">
          Outside<br/>the box.
        </div>
      </div>
    </Slider>
  </div>
</template>

<script>
import ContactForm from '../components/ContactForm.vue';
import Slider from '../components/Slider.vue';

export default {
  components: {
    ContactForm,
    Slider
  }

}
</script>
