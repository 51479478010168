<template>
  <div class="w-screen flex flex-col relative h-full justify-center"
       v-bind:class="{[(breakpoint||'sm')+':w-full']: true}">
    <div class="flex lg:flex-nowrap sm:justify-center" :style="'width: calc('+slides+' * 100vw)'"
         ref="slider" @mousedown="onSliderDragStart" @mousemove="onSliderDrag" @touchstart="onSliderDragStart"
         @touchmove="onSliderDrag" @touchend="onSliderDragEnd"
         v-bind:class="{[(breakpoint||'sm')+':max-w-full']: true, [(breakpoint||'sm')+':flex-wrap']: true, [(breakpoint||'sm')+':slider']: true}">
      <slot></slot>
    </div>

    <div class="flex flex-row justify-center mt-8 gap-4" v-bind:class="{[(breakpoint||'sm')+':hidden']: true}">
      <div class="rounded-full bg-white opacity-50 w-2 h-2 transition-opacity duration-300" v-for="index in slides"
           v-bind:class="{'opacity-100':curSlide === index-1}"></div>
    </div>
  </div>
</template>

<script>
export default {

  props: [
    'breakpoint'
  ],
  methods: {
    onSliderDragStart(ev) {
      if (this.dragging) return;

      //ev.preventDefault();
      this.prevMousePos = null;
      this.dragging = new Date().getTime();
      this.$refs.slider.style.transition = 'initial';

      if (this.animateSlider !== false) {
        if (this.animateSlider !== true) {
          clearTimeout(this.animateSlider);
        }
        this.animateSlider = false;
      }
    },

    onSliderDragEnd(ev) {

      if (this.dragging === 0) return;

      ev.preventDefault();
      this.dragging = 0;

      const pos = -this.$refs.slider.getBoundingClientRect().left / window.innerWidth;
      this.curSlide = pos > this.curSlide + 0.25 ? this.curSlide + 1 : (pos < this.curSlide - 0.25 ? this.curSlide - 1 : this.curSlide);
      this.$refs.slider.style.transition = 'transform 0.5s';
      this.$refs.slider.style.transform = `translateX(-${this.curSlide * 100}vw)`;

      this.animateSlider = setTimeout(() => {
        this.animateSlider = true;
      }, 10000);
    },

    onSliderDrag(ev) {

      if (this.dragging === 0) return;
      ev.preventDefault();

      const pageX = ev.pageX || ev.touches?.[0].pageX || 0;
      const pageY = ev.pageY || ev.touches?.[0].pageY || 0;
      if (!this.prevMousePos) {
        this.prevMousePos = [pageX, pageY];
        return;
      }

      const curSliderPos = Math.max(Math.min(-this.$refs.slider.getBoundingClientRect().left + this.prevMousePos[0] - pageX, window.innerWidth * (this.slides - 1)), 0);
      this.prevMousePos = [pageX, pageY];

      this.$refs.slider.style.transform = `translateX(-${curSliderPos}px)`;
    }
  },

  data() {
    return {
      prevMousePos: null,
      dragging: 0,
      animateSlider: true,
      curSlide: 0,
      slides: 0
    }
  },

  mounted() {
    this.slides = this.$refs.slider?.children.length;

    window.addEventListener('mouseup', this.onSliderDragEnd.bind(this));

    /*setInterval(() => {

      if (this.animateSlider !== true) return;
      this.curSlide = (-Math.floor(this.$refs.slider.getBoundingClientRect().left / window.innerWidth) + 1) % this.slides;
      this.$refs.slider.style.transition = 'transform 0.5s';
      this.$refs.slider.style.transform = `translateX(-${this.curSlide * 100}vw)`
    }, 2000);*/
  }
};
</script>
