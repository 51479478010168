<template>
  <footer id="footer">
    <div id="footer-container">
      <div id="footer-container-inner">
        <div class="footer-section">
          <ul>
            <li class="footer-section-segment">
              <span>
                  <router-link to="/">
                    <img src="../img/logo.png" class="w-12" alt="Logo">
                  </router-link>
              </span>
            </li>
            <li class="footer-section-segment">
              <span>
                  Copyright © {{ currentYear }}
              </span>
            </li>
          </ul>
        </div>
        <div class="footer-section">
          <p class="footer-section-segment-title">Where am I?</p>
          <span class="text-gray-400">
            Budapest<br/>
            Hungary
          </span>
        </div>
        <div class="footer-section">
          <p class="footer-section-segment-title">Links</p>
          <ul>
            <!--<li class="footer-section-li">
              <router-link to="terms-of-service" class="footer-section-li-link">Terms & Conditions</router-link>
            </li>
            <li class="footer-section-li">
              <router-link to="privacy-policy" class="footer-section-li-link">Privacy policy</router-link>
            </li>
            <li class="footer-section-li">
              <a href="#" class="footer-section-li-link">Cookie policy</a>
            </li>-->
            <li class="footer-section-li">
              <router-link class="footer-section-li-link" to="/contact">
                Contact me
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer-section">
          <p class="footer-section-segment-title">Follow Me</p>
          <ul>
            <li class="footer-section-li">
              <a href="https://github.com/dvarnai" class="footer-section-li-link"><img
                  src="../icons/Insignificon-Github.svg" class="w-6 inline pr-2" alt="GitHub icon">GitHub</a>
            </li>

            <li class="footer-section-li">
              <a href="https://linkedin.com/in/daniel-varnai" class="footer-section-li-link"><img
                  src="../icons/Insignificon-LinkedIn.svg" class="w-6 inline pr-2" alt="LinkedIn icon">LinkedIn</a>
            </li>

            <li class="footer-section-li">
              <a href="https://instagram.com/daniel.varnai" class="footer-section-li-link"><img
                  src="../icons/Insignificon-Instagram-New.svg" class="w-6 inline pr-2"
                  alt="Instagram icon">Instagram</a>
            </li>

            <li class="footer-section-li">
              <a href="https://facebook.com/dvarnai" class="footer-section-li-link"><img
                  src="../icons/Insignificon-Facebook-i.svg" class="w-6 inline pr-2" alt="Facebook icon">Facebook</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
}
</script>
